<!-- 大乐透跨度尾数走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1629609108732" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2399" width="45" height="45">
                        <path d="M452.846933 52.986311h117.771378l-127.724089 392.436622h-117.543822z" fill="#322C2B"
                            p-id="2400"></path>
                        <path d="M29.641956 446.839467l36.189866-111.889067 333.969067 242.591289-36.420267 111.741155z"
                            fill="#0C7CBE" p-id="2401"></path>
                        <path d="M273.965511 971.013689l-95.274667-69.290667 333.7216-242.212978 95.379912 69.048889z"
                            fill="#EFB918" p-id="2402"></path>
                        <path d="M847.798044 900.616533l-95.277511 69.1456-127.374222-392.220444 95.152356-69.304889z"
                            fill="#14934A" p-id="2403"></path>
                        <path d="M958.196622 333.348978l36.161422 111.746844H581.808356l-36.411734-111.746844z"
                            fill="#D7282A" p-id="2404"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国体育彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>大 乐 透 跨 度 尾 数 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a">期号</th>
                        <!-- <th rowspan="2" class="ranks_a1">星期</th> -->
                        <th colspan="10" class="ranks_b">跨 度 尾 数 走 势 图</th>
                        <th colspan="3" class="ranks_b">跨度012路</th>
                        <th colspan="3" class="ranks_b">跨度147路</th>
                        <th colspan="3" class="ranks_b">跨度258路</th>
                        <th colspan="2" class="ranks_b">奇 偶 </th>
                        <th colspan="2" class="ranks_b"> 大 小 </th>
                        <th colspan="2" class="ranks_b">质 合</th>
                        <th colspan="10" class="ranks_b">跨度振幅</th>
                        <th colspan="3" class="ranks_b">振幅012路</th>
                        <th colspan="3" class="ranks_b">振幅147路</th>
                        <th colspan="3" class="ranks_b">振幅258路</th>
                        <th colspan="2" class="ranks_b"> 奇 偶 </th>
                        <th colspan="2" class="ranks_b"> 大 小 </th>
                        <th colspan="2" class="ranks_b">质 合</th>
                        <th colspan="5" class="ranks_b">跨度五行走势图</th>
                    </tr>
                    <tr>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">奇</th>
                        <th class="ranks_c">偶</th>
                        <th class="ranks_c">大</th>
                        <th class="ranks_c">小</th>
                        <th class="ranks_c">质</th>
                        <th class="ranks_c">合</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">奇</th>
                        <th class="ranks_c">偶</th>
                        <th class="ranks_c">大</th>
                        <th class="ranks_c">小</th>
                        <th class="ranks_c">质</th>
                        <th class="ranks_c">合</th>
                        <th class="ranks_c">金</th>
                        <th class="ranks_c">木</th>
                        <th class="ranks_c">水</th>
                        <th class="ranks_c">火</th>
                        <th class="ranks_c">土</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item2, x) in dlt" :key="x">
                        <td>{{ item2.expect }}</td>
                        <!-- <td>{{}}</td> -->
                        <!-- 合值尾数 -->
                        <td class="b-red" v-for="(item, i) in red" :key="i">
                            <div :class="item == kua[x] ? 'q-red' : ''">
                                {{ item == kua[x] ? item : "" }}
                            </div>
                        </td>
                        <!-- 012路 -->
                        <td class="b-lan" v-for="(item, i) in lye" :key="i">
                            <div :class="item == (kua[x] % 10) % 3 ? 'q-lan' : ''">
                                {{ item == kua[x] % 3 ? item : "" }}
                            </div>
                        </td>
                        <!-- 147路 -->
                        <td class="b-red" v-for="(item, i) in ysq" :key="i">
                            <div :class="
                  item ==
                  (([1, 5, 6, 9].indexOf(kua[x]) == -1 ? '' : 1) ||
                    ([0, 2, 4].indexOf(kua[x]) == -1 ? '' : 4) ||
                    ([3, 7, 8].indexOf(kua[x]) == -1 ? '' : 7))
                    ? 'q-red'
                    : ''
                ">
                                {{
                                item ==
                                (([1, 5, 6, 9].indexOf(kua[x]) == -1 ? "" : 1) ||
                                ([0, 2, 4].indexOf(kua[x]) == -1 ? "" : 4) ||
                                ([3, 7, 8].indexOf(kua[x]) == -1 ? "" : 7))
                                ? item
                                : ""
                                }}
                            </div>
                        </td>
                        <!-- 258路 -->
                        <td class="b-lan" v-for="(item, i) in ewb" :key="i">
                            <div :class="
                  item ==
                  (([2, 7, 9].indexOf(kua[x]) == -1 ? '' : 2) ||
                    ([0, 3, 5].indexOf(kua[x]) == -1 ? '' : 5) ||
                    ([1, 4, 6, 8].indexOf(kua[x]) == -1 ? '' : 8))
                    ? 'q-lan'
                    : ''
                ">
                                {{
                                item ==
                                (([2, 7, 9].indexOf(kua[x]) == -1 ? "" : 2) ||
                                ([0, 3, 5].indexOf(kua[x]) == -1 ? "" : 5) ||
                                ([1, 4, 6, 8].indexOf(kua[x]) == -1 ? "" : 8))
                                ? item
                                : ""
                                }}
                            </div>
                        </td>
                        <!-- 合尾奇偶 -->
                        <td class="b-red" v-for="(item, i) in jo" :key="i">
                            <div :class="item == (kua[x] % 2 == 0 ? '偶' : '奇') ? 'q-red' : ''">
                                {{ item == (kua[x] % 2 == 0 ? "偶" : "奇") ? item : "" }}
                            </div>
                        </td>
                        <!-- 合尾大小 -->
                        <td class="b-lan" v-for="(item, i) in dx" :key="i">
                            <div :class="item == (4 >= kua[x] ? '小' : '大') ? 'q-lan' : ''">
                                {{ item == (4 >= kua[x] ? "小" : "大") ? item : "" }}
                            </div>
                        </td>
                        <!-- 合尾质合 -->
                        <td class="b-red" v-for="(item, i) in zh" :key="i">
                            <div :class="
                  item == ([1, 2, 3, 5, 7].indexOf(kua[x]) == -1 ? '合' : '质')
                    ? 'q-red'
                    : ''
                ">
                                {{
                                item == ([1, 2, 3, 5, 7].indexOf(kua[x]) == -1 ? "合" : "质")
                                ? item
                                : ""
                                }}
                            </div>
                        </td>
                        <!-- 合尾振幅 -->
                        <td class="b-lan" v-for="(item, i) in zf" :key="i">
                            <div :class="item == zf2[x] ? 'q-lan' : ''">
                                {{ item == zf2[x] ? item : "" }}
                            </div>
                        </td>
                        <!-- 振幅012路 -->
                        <td class="b-red" v-for="(item, i) in lye" :key="i">
                            <div :class="item == (zf2[x] % 10) % 3 ? 'q-red' : ''">
                                {{ item == (zf2[x] % 10) % 3 ? item : "" }}
                            </div>
                        </td>
                        <!-- 振幅147路 -->
                        <td class="b-lan" v-for="(item, i) in ysq" :key="i">
                            <div :class="
                  item ==
                  (([1, 5, 6, 9].indexOf(zf2[x]) == -1 ? '' : 1) ||
                    ([0, 2, 4].indexOf(zf2[x]) == -1 ? '' : 4) ||
                    ([3, 7, 8].indexOf(zf2[x]) == -1 ? '' : 7))
                    ? 'q-lan'
                    : ''
                ">
                                {{
                                item ==
                                (([1, 5, 6, 9].indexOf(zf2[x]) == -1 ? "" : 1) ||
                                ([0, 2, 4].indexOf(zf2[x]) == -1 ? "" : 4) ||
                                ([3, 7, 8].indexOf(zf2[x]) == -1 ? "" : 7))
                                ? item
                                : ""
                                }}
                            </div>
                        </td>
                        <!-- 振幅258路 -->
                        <td class="b-red" v-for="(item, i) in ewb" :key="i">
                            <div :class="
                  item ==
                  (([2, 7, 9].indexOf(zf2[x]) == -1 ? '' : 2) ||
                    ([0, 3, 5].indexOf(zf2[x]) == -1 ? '' : 5) ||
                    ([1, 4, 6, 8].indexOf(zf2[x]) == -1 ? '' : 8))
                    ? 'q-red'
                    : ''
                ">
                                {{
                                item ==
                                (([2, 7, 9].indexOf(zf2[x]) == -1 ? "" : 2) ||
                                ([0, 3, 5].indexOf(zf2[x]) == -1 ? "" : 5) ||
                                ([1, 4, 6, 8].indexOf(zf2[x]) == -1 ? "" : 8))
                                ? item
                                : ""
                                }}
                            </div>
                        </td>
                        <!-- 振幅奇偶 -->
                        <td class="b-lan" v-for="(item, i) in jo" :key="i">
                            <div :class="
                  item == ((zf2[x] % 10) % 2 == 0 ? '偶' : '奇') ? 'q-lan' : ''
                ">
                                {{ item == ((zf2[x] % 10) % 2 == 0 ? "偶" : "奇") ? item : "" }}
                            </div>
                        </td>
                        <!-- 振幅大小 -->
                        <td class="b-red" v-for="(item, i) in dx" :key="i">
                            <div :class="item == (4 >= zf2[x] ? '小' : '大') ? 'q-red' : ''">
                                {{ item == (4 >= zf2[x] ? "小" : "大") ? item : "" }}
                            </div>
                        </td>
                        <!-- 振幅质合 -->
                        <td class="b-lan" v-for="(item, i) in zh" :key="i">
                            <div :class="
                  item == ([1, 2, 3, 5, 7].indexOf(zf2[x]) == -1 ? '合' : '质')
                    ? 'q-lan'
                    : ''
                ">
                                {{
                                item == ([1, 2, 3, 5, 7].indexOf(zf2[x]) == -1 ? "合" : "质")
                                ? item
                                : ""
                                }}
                            </div>
                        </td>
                        <!-- 五行走势图 -->
                        <td class="b-red" v-for="(item, i) in wx" :key="i">
                            <div :class=" item == (1 == zf2[x] || 9 == zf2[x]
                    ? '金'
                    : '' || 2 == zf2[x] || 8 == zf2[x]
                    ? '木'
                    : '' || 3 == zf2[x] || 7 == zf2[x]
                    ? '水'
                    : '' || 4 == zf2[x] || 6 == zf2[x]
                    ? '火'
                    : '' || 0 == zf2[x] || 5 == zf2[x]
                    ? '土'
                    : '')
                    ? 'q-red'
                    : ''
                ">
                                {{
                                item ==
                                (1 == zf2[x] || 9 == zf2[x]
                                ? "金"
                                : "" || 2 == zf2[x] || 8 == zf2[x]
                                ? "木"
                                : "" || 3 == zf2[x] || 7 == zf2[x]
                                ? "水"
                                : "" || 4 == zf2[x] || 6 == zf2[x]
                                ? "火"
                                : "" || 0 == zf2[x] || 5 == zf2[x]
                                ? "土"
                                : "")
                                ? item
                                : ""
                                }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x, i) in 55" :key="i">
                            <div :class="x == i + 1 ? 'xuan' : ''">
                                {{ "?" }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x, i) in 55" :key="i">
                            <div :class="x == i + 1 ? 'xuan' : ''">
                                {{ "?" }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x, i) in 55" :key="i">
                            <div :class="x == i + 1 ? 'xuan' : ''">
                                {{ "?" }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import mitt from '@/js/mitt.js'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: "red-tail",
        components: {},
        data() {
            // 数据源
            return {

                red: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                kua: [],
                lye: [0, 1, 2],
                ysq: [1, 4, 7],
                ewb: [2, 5, 8],
                jo: ["奇", "偶"],
                dx: ["大", "小"],
                zh: ["质", "合"],
                zf: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                zf2: [0],
                wx: ["金", "木", "水", "火", "土"],

            };
        },
        mounted() {
            this.kua1();
            this.mzf();
            this.find()
        },
        computed: {
            dlt() {
                return this.$store.state.dlt;
            },
        },
        methods: {
            // 函数体部分，js的主要逻辑控制
            find() {
                document.documentElement.scrollTop = 0;
                if (this.dlt == '') {
                    mitt.emit('dlt');
                }
            },
            kua1() {
                //合值尾数
                this.kua = []
                var arr = new Array();
                for (var i = 0; i < this.dlt.length; i++) {
                    var kua = 0;
                    kua = this.dlt[i].red[4] % 10 - this.dlt[i].red[0] % 10
                    arr.push(Math.abs(kua));
                    this.kua = arr;
                }
            },
            mzf() {
                //合尾振幅
                this.zf2 = [0]
                var arr = new Array();
                for (var i = 0; i < this.kua.length - 1; i++) {
                    arr = this.kua[i] - this.kua[i + 1];
                    this.zf2.push(Math.abs(arr));
                }

            },
        },
        props: {},
        watch: {
            dlt() {
                this.kua1();
                this.mzf();
            }
        }
    };
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: rgb(224, 202, 5);
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a1 {
        width: 25px;
    }

    .ranks_c {
        width: 23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;
    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }

    .b-red {
        height: 23px;
        background-color: rgb(221, 201, 171);

    }

    .b-lan {
        background-color: rgb(184, 200, 230);
    }

    .q-red {
        background-color: rgb(158, 29, 147);
        border-radius: 15px;
        color: #fff;
    }

    .q-lan {
        background-color: rgb(212, 164, 3);
        color: #fff;
        border-radius: 15px;
    }

    .no {
        display: none;
    }
</style>